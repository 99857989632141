import * as React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'

import { Block, Button, Text, Title } from '@superlogica/design-ui'
import { Link } from 'components/helpers'

import * as S from './styled'

const SectionError = () => (
  <S.SectionError>
    <Container>
      <S.InnerWrapper>
        <Row align="center" justify="space-between">
          <Col xs={12} md={6}>
            <Block tag="header" marginBottom={{ xxxs: 40, md: 0 }}>
              <Title
                size="display02"
                font="heading"
                tag="h1"
                color="babar0"
                weight="bold"
                marginTop={8}
                marginBottom={24}
              >
                Página não encontrada
              </Title>
              <Text color="babar0" marginBottom={24}>
                Desculpe, mas a página que você está procurando não foi
                encontrada.
              </Text>
              <Button
                tag={Link}
                title="Voltar para o início"
                color="warning"
                weight="semiBold"
                to="/"
              >
                Voltar para o início
              </Button>
            </Block>
          </Col>
        </Row>
      </S.InnerWrapper>
    </Container>
  </S.SectionError>
)

export default SectionError
